import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  ApplicationGeneralEvents,
  DashboardEvents,
  FinancingSolutionEvents,
  KycEvents,
  OnboardingEvents,
  PartnerOnboardingEvents,
  PartnerProductRequestEvents,
  ExternalProductRequestEvents,
  ProductRequestEvents,
  ProductSelectorEvents,
  SystemEvents,
  UserProfileEvents,
  OpenbankingEvents,
} from "./AppInsightEvents";

import { useHistory, useLocation } from "react-router-dom";
import config from "../api/config";

declare global {
  interface Window {
    analytics?: {
      events?: any;
      trackEvent?: any;
      trackException?: any;
    };
    appInsightEvents?: any;
  }
}

const initialState: { [key: string]: any } = {
  loading: false,
};

export const AnalyticsContext = createContext(initialState);

export const useAnalyticsContext = () => {
  return useContext<any>(AnalyticsContext);
};

interface Props {
  children: React.ReactNode;
}

interface UserInfo {
  cui?: string;
  email?: string;
  phoneNumber?: string;
  fullName?: string;
  uuid?: string;
  sessionId?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  partner?: string;
}

window.analytics = window.analytics || {};

window.analytics = {
  events: {
    ...OnboardingEvents,
    ...DashboardEvents,
    ...ExternalProductRequestEvents,
    ...ProductRequestEvents,
    ...KycEvents,
    ...SystemEvents,
    ...UserProfileEvents,
    ...ProductSelectorEvents,
    ...FinancingSolutionEvents,
    ...PartnerOnboardingEvents,
    ...PartnerProductRequestEvents,
    ...ApplicationGeneralEvents,
    ...OpenbankingEvents
  },
};

export const AnalyticsProvider = ({ children }: Props) => {
  const [appInsightsClient, setAppInsightsClient] =
    useState<ApplicationInsights>();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const history = useHistory();
  const location = useLocation();

  const updateUserInfo = (newInfo: UserInfo) => {
    const updatedUserInfo = { ...userInfo, ...newInfo };
    setUserInfo(updatedUserInfo);
  };

  ///Initialize app insights
  useEffect(() => {
    if (process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
      const client = new ApplicationInsights({
        config: {
          instrumentationKey:
            process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
        },
      });
      client.loadAppInsights();

      setAppInsightsClient(client);
    }
  }, []);

  ///set proper operation_name in tags
  useEffect(() => {
    appInsightsClient?.addTelemetryInitializer((e) => {
      e.tags!["ai.operation.name"] = history.location.pathname;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, appInsightsClient]);

  ///create RouteChanged event, with action (POP = back button!!!)
  useEffect(() => {
    appInsightsTrackEvent(window.analytics?.events.RouteChanged, {
      action: history.action,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.action, location]);

  const appInsightsTrackEvent = (
    eventName: string,
    eventCustomProperties: any
  ) => {
    let enrichedProperties = {
      ...eventCustomProperties,
    };
    if (userInfo) {
      enrichedProperties = {
        ...enrichedProperties,
        ...{
          cui: userInfo?.cui,
          email: userInfo?.email?.toLowerCase(),
          phone: userInfo?.phoneNumber,
          fullName: `${userInfo?.firstName} ${userInfo?.lastName}`,
          clientSessionId: config.sessionId,
          pathname: location.pathname,
          partner: userInfo?.partner,
          //existing user
          userId: userInfo?.userId,
        },
      };
    }
    appInsightsClient?.trackEvent({
      name: eventName,
      properties: enrichedProperties,
    });
  };

  const appInsightsTrackException = (error?: any) => {
    console.info("Tracking exception !!!!!!!!", error, SeverityLevel.Error);
    appInsightsClient?.trackException({
      exception: new Error(error),
      severityLevel: SeverityLevel.Error,
    });
  };

  window.analytics = {
    ...window.analytics,
    trackEvent: appInsightsTrackEvent,
    trackException: appInsightsTrackException,
  };

  return (
    <AnalyticsContext.Provider
      value={{
        updateUserInfo,
        appInsightsClient,
        userInfo,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
