export const OFFER_STATE = {
  INITIAL: "Initial",
  IN_PROGRESS: "InProgress",
  NEEDS_MANUAL_APPROVAL: "NeedsManualApproval",
  REJECTED: "Rejected",
  FINISHED: "Finished",
};

export const GROUP_TYPE = {
  INFO: "Info",
  ELIGIBILITY: "Eligibility",
  REQUIREDDOCUMENTS: "RequiredDocuments",
  OFFER: "Offer",
  ProductSelectorSmallAmount: "ProductSelectorSmallAmount",
  ProductSelectorMediumAmount: "ProductSelectorMediumAmount",
  ProductSelectorBigAmount: "ProductSelectorBigAmount",
};

export const DETAIL_TYPE = {
  EMOJITITLE: "EmojiTitle",
  APPROVALTIMETEXT: "ApprovalTimeText",
  REALGUARANTEETEXT: "RealGuaranteeText",
  ELIGIBILITYCONDITION: "EligibilityCondition",
  REQUIREDDOCUMENT: "RequiredDocument",
  SUBTITLEHTML: "SubtitleHtml",
  APPROVALTIMETEXTUNITS: "ApprovalTimeTextUnits",
  APPROVALTIMETEXTPILL: "ApprovalTimeTextPill",
  APPROVALTIMETEXTWARNING: "ApprovalTimeTextWarning",
  OFFERBENEFITTITLE: "OfferBenefitTitle",
  OFFERBENEFITTEXT: "OfferBenefitText",
  OFFERINFOTEXT: "OfferInfoText",
  OFFERNEXTSTEPTEXT: "OfferNextStepText",
  OPTIONALDOCUMENT: "OptionalDocument",
  GUARANTEETYPETITLE: "GuaranteeTypeTitle",
  GUARANTEETYPETEXT: "GuaranteeTypeText",
};

export const PRODUCT_TYPE = {
  FILBO: "Filbo",
  STANDARDEASI: "StandardEasi",
  FILBO24: "Filbo24",
  FILBOSHOP: "FilboShop",
  FILBOTAX: "FilboTax",
  FILBOSPLIT: "FilboSplit",
  STANDARD: "Standard",
  FILBOAGRI: "FilboAgri",
  CURRENTACCOUNT: "CurrentAccount",
};

export const BUSINESS_MEANING = {
  NUMARDEANGAJATIINTERVAL: "NumarDeAngajatiInterval",
  DESTINATIACREDITULUI: "DestinatiaCreditului",
  SEZONALITATEAFACERE: "SezonalitateAfacere",
  CLASIFICARETIPCOMPANIEDECLARATA: "ClasificareTipCompanieDeclarata",
};

export const PRODUCT_SELECTOR_QUESTIONS = [
  {
    orderIndex: 0,
    businessMeaning: BUSINESS_MEANING.CLASIFICARETIPCOMPANIEDECLARATA,
    question: "Care este tipul afacerii tale?",
    options: [
      {
        id: 0,
        label: "Companie (SRL, SA)",
        value: "Societate Comerciala",
        selected: false,
      },
      {
        id: 1,
        label: "Antreprenor individual (PFA, II, IF, PFL)",
        value: "Societate Individuala",
        selected: false,
      },
    ],
  },
  {
    orderIndex: 1,
    businessMeaning: BUSINESS_MEANING.DESTINATIACREDITULUI,
    question: "Ce plănuiești să faci cu banii obținuți din credit?",
    options: [
      {
        id: 0,
        label: "Investiții",
        value: "Investitii",
        selected: false,
      },
      {
        id: 1,
        label: "Achiziție marfă",
        value: "Achizitie marfa",
        selected: false,
      },
      {
        id: 2,
        label: "Plată taxe",
        value: "Plata taxe",
        selected: false,
      },
      {
        id: 3,
        label: "Plată salarii",
        value: "Salarii",
        selected: false,
      },
      {
        id: 4,
        label: "Achiziție inputuri agricole",
        value: "Achizitie inputuri agricole",
        selected: false,
      },
      {
        id: 5,
        label: "Alte nevoi de capital de lucru",
        value: "Capital de lucru",
        selected: false,
      },
      {
        id: 6,
        label: "Mixt - capital de lucru și investiții",
        value: "Mixt",
        selected: false,
      },
      {
        id: 7,
        label: "Refinanțarea altui credit",
        value: "Refinantare",
        selected: false,
      },
    ],
  },
  {
    orderIndex: 2,
    businessMeaning: BUSINESS_MEANING.NUMARDEANGAJATIINTERVAL,
    question: "Câți angajați ai?",
    options: [
      {
        id: 0,
        label: "0-9",
        value: "0-9",
        selected: false,
      },
      {
        id: 1,
        label: "10-49",
        value: "10-49",
        selected: false,
      },
      {
        id: 2,
        label: "50-249",
        value: "50-249",
        selected: false,
      },
      {
        id: 3,
        label: "Peste 250",
        value: "Peste 250",
        selected: false,
      },
    ],
  },
];

export const API_ERROR_CODE = {
  ExistingFilboUserWithDifferentCompany:
    "Adresa de email folosită este asociată unui cont existent Filbo, dar cu un CUI diferit.",
  ExistingPartnerCustomerWithDifferentEmail:
    "Există o înregistrare a acestei firme, dar cu o adresă de email diferită.",
  ApplicationAlreadyInProgress:
    "Aveți deja o cerere în progres, verificați email-ul pentru a continua aplicația.",
  RegistrationSessionStartedWithDifferentEmailAddress:
    "Sesiuni de înregistrare a fost începută cu o altă adresă de email, folosiți adresa de email cu care ați început sesiunea.",
  Default:
    "A apărut o eroare. Contactați echipa de suport prin apăsarea butonului din dreapta jos.",
};
